<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>美舒家-奇安慧</span></div>
    </div>
    <!-- 上部分 -->
    <div class="talk_top">
      <div class="talk_top_cont">
        <div class="talk_top_cont_l">
          <h1>美舒家家庭服务</h1>
          <p>
            美舒家围绕各类建筑的外部清洁，外墙清洗，外墙刷漆，外墙修补，幕墙清洗，房屋翻修，内部装修，维修，保洁及各类型电器和设备的安装-墙面清洗，墙面刷漆，房屋装修，房屋装修维修，保洁，垃圾清运，家电清洗维修，水电维修，
            砸墙，改水电，门窗定制，窗纱定制，摄像头，弱点，监控，贴瓷砖，批腻子，墙面粉刷，填缝，擦窗子，擦玻璃，精细擦玻璃，保洁，开荒保洁，厨房蒸洗，卫生间蒸洗，全屋打扫，深度保洁，灯具安装，灯具清洗，沙发清洗，地毯清洗，床品除螨，床品清洗，窗帘清洗，
            日常保洁,搬家，整理收纳，油烟机清洗，热水器清洗，空调清洗，洗衣机清洗，冰箱清洗，油烟机维修，冰箱维修，空调维修，洗衣机维修，燃气灶安装维修，换气扇安装，花洒安装，水龙头安装，洗面池安装，桌椅安装，木床拆卸安装，净水设备安装，管道疏通，防水补漏，墙面翻新，
            电脑办公设备，打印机安装维修，婚礼策划，红包喜事策划，视频录制等服务家庭
          </p>
          <h1>奇安慧企业服务</h1>
          <p>
            奇安慧围绕品牌策划，门店设计，门店装修，门店筹备，物料设计，物料供应，供应链支持，收银支持，门店运营提供个性化的解决方案，致力于将最新的产品和最先进的技术应用到各行各业的数字化转型中。携手行业合作伙伴，共同服务于各行各业的数字化发展，助力智慧建设。例如：
            公司选址，公司注册，财税业务，商标设计，商标注册，商标答辩，会议大屏（goodview），广告标牌（上海仙视goodview,深圳数拓，三星，小米，联想等品牌），餐厅菜牌，公司宣传片拍摄，菜单制作，菜品拍摄，公司内控系统建设（含oa系统，crm,财务系统，收银系统（天才商龙，美团，客如云，美团，收钱吧等），供应链管理系统，会员系统，库存系统（进销存），连锁配送系统，采购系统，
            扫码点餐系统，酒店管理系统，财税系统，邮件系统，销售系统，微信，钉钉，人员管理系统，绩效管理系统），酒店管理系统（天欣，中软，西软，满江红，绿云等品牌），门店活动策划，门店保洁，门店设备维修等服务企业
          </p>
        </div>
        <div class="talk_top_cont_c">
          <div class="talk_top_cont_c_t">
            <div class="talk_top_cont_c_t_l">
              <p>奇安慧影视</p>
              <p>服务时间：7:30-22:30</p>
              <p>TEL： 17302922307</p>
            </div>
            <div class="talk_top_cont_c_t_r">
              <p>奇安慧企业热线</p>
              <p>服务时间：7:30-22:30</p>
              <p>TEL: 17302922307</p>
            </div>
          </div>

          <div class="talk_top_cont_c_b">
            <div class="talk_top_cont_c_b_l">
              <p>美舒家热线</p>
              <p>服务时间：7:30-22:30</p>
              <p>TEL 17302922307</p>
            </div>
            <div class="talk_top_cont_c_b_r">
              <p>抖音运营热线电话</p>
              <p>服务时间：7:30-22:30</p>
              <p>TEL: 17302922307</p>
            </div>
          </div>
        </div>
        <div class="talk_top_cont_r">
          <img src="../assets/img/lianxi/编组 10.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 下部分 -->
    <div class="talk_bottom">
      <img src="../assets/img/lianxi/位图.png" alt="" />
    </div>
    <applyFor></applyFor>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import applyFor from "../components/applyFor.vue";

export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
    applyFor,
  },
  data() {
    return {
      h1: "中润恒通",
      Img1: require("../assets/img/keji/矿山banner1.png"),

      p1: "更多最新精彩资讯，感受企业魅力",
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 联系我们 */

.talk_top_cont {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  padding-top: 90px;

  margin: 0 auto;
}
.talk_top_cont_l {
  width: 235px;
  height: 134px;
}
.talk_top_cont_l > h1 {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #23337f;
  line-height: 45px;
}
.talk_top_cont_l p {
  width: 235px;
  height: 80px;
  font-size: 4px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 6;
  color: #f6f6f6;
  line-height: 40px;
}
.talk_top_cont_c {
  display: flex;
  width: 487px;
}
.talk_top_cont_c {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 25px;
}

.talk_top_cont_c_t_l {
  margin-right: 60px;
}
.talk_top_cont_r {
  width: 117px;
}
.talk_top_cont_r img {
  border: dashed;
}
.talk_top_cont_c_t_r {
  margin-top: 36px;
}
.talk_top_cont_c_b_r {
  margin-top: 36px;
}
.talk_bottom {
  width: 1200px;
  margin: 91px auto;
  margin-bottom: 81px;
}
</style>
