<template>
  <!-- star -->

  <div class="kejipage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>奇安慧企业服务</span></div>
    </div>
    <!-- 企业服务 -->
    <div class="TechnologySector">
      <div class="TechnologySector_cont">
        <h1>奇安慧企业服务</h1>
        <div class="TechnologySector_cont_bottom">
          <img src="../assets/img/keji/yanhua.png" alt="" />

          <div class="TechnologySector_cont_bottom_text">
            <div class="TechnologySector_cont_bottom_text_div">
              <span
                >奇安慧品牌以科技为引擎，以创新为动力，依托云计算、大数据、人工智能等前沿技术，携手华为、阿里、H3C、浪潮、锐捷等行业巨头做好品牌策划，门店设计，门店装修，门店筹备，物料设计，物料供应，供应链支持，收银支持，门店运营。为餐饮，零售，酒店，校园，金融，物业等行业客户提供建设和运营软硬件一体化解决方案，致力于成为“智慧城市、智慧校园”的践行者。</span
              >
            </div>
            <p>
              公司企业服务品牌“奇安慧”契合国家数字化经济发展方向，积极探索，不断拓展，联合腾讯，华为、阿里等国际一流信息化企业，围绕品牌策划，门店设计，门店装修，门店筹备，物料设计，物料供应，供应链支持，收银支持，门店运营提供个性化的解决方案，致力于将最新的产品和最先进的技术应用到各行各业的数字化转型中。携手行业合作伙伴，共同服务于各行各业的数字化发展，助力智慧建设。例如：
              公司选址，公司注册，财税业务，商标设计，商标注册，商标答辩，会议大屏（goodview），广告标牌（上海仙视goodview,深圳数拓，三星，小米，联想等品牌），餐厅菜牌，公司宣传片拍摄，菜单制作，菜品拍摄，公司内控系统建设（含oa系统，crm,财务系统，收银系统（天才商龙，美团，客如云，美团，收钱吧等），供应链管理系统，会员系统，库存系统（进销存），连锁配送系统，采购系统，
              扫码点餐系统，酒店管理系统，财税系统，邮件系统，销售系统，微信，钉钉，人员管理系统，绩效管理系统），酒店管理系统（天欣，中软，西软，满江红，绿云等品牌），门店活动策划，门店保洁，门店设备维修等服务。
            </p>
          </div>
          <div class="TechnologySector_cont_bottom_text_english">
            TECHNOLOGY
          </div>
        </div>
      </div>
    </div>
    <!-- 教育 -->

    <!-- 城市 -->
    <div class="Citi"></div>
    <!-- 华为云 -->
    <div class="HuaWei">
      <div class="HuaWei_cont">
        <h1>云服务</h1>
        <p class="HuaWei_cont_p">
          奇安慧品牌携手腾讯，阿里，提供专业、安全、可信的云计算产品，全球领先的研发创新能力，满足更广泛业务需求的行业解决方案，建设和谐共赢的云生态系统。
        </p>
        <div class="HuaWei_cont_item">
          <div class="HuaWei_cont_item_l">
            <div class="HuaWei_cont_item_l1">
              <p>弹性云服务器</p>
              <span
                >弹性云服务器 云容器引 <br />
                弹性伸缩服务 镜像服务 专属云</span
              >
            </div>
            <div class="HuaWei_cont_item_l2">
              <p>高可用</p>
              <span
                >云硬盘 对象储存服务 云硬盘备份 <br />
                弹性伸缩服务 镜像服务 专属云</span
              >
            </div>
          </div>
          <div class="HuaWei_cont_item_img">
            <img src="../assets/img/keji/编组 11.png" alt="" />
          </div>
          <div class="HuaWei_cont_item_r">
            <div class="HuaWei_cont_item_r1">
              <p>云安全</p>
              <span
                >Anti-DDos流量清洗 漏洞扫描服务 安全指数服务 <br />
                企业主机安全 WEB应用防火墙 数据库安全服务</span
              >
            </div>
            <div class="HuaWei_cont_item_r2">
              <p>云数据库</p>
              <span>云数据库MySQL 分部式缓存服务 文档数据库服务 </span>
            </div>
          </div>
        </div>
        <div class="HuaWei_cont_item_c">
          <p>云网络</p>
          <span>虚拟私有云 弹性负载均衡 虚拟专用网络</span>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "企业服务",
      Img1: require("../assets/img/keji/矿山banner1.png"),
      arrList: [
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.kejipage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 企业服务 */
.TechnologySector_cont {
  width: 1200px;
  height: 810px;
  margin: 0 auto;
  padding-top: 64px;
}
.TechnologySector_cont h1 {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
  text-align: center;
}
.TechnologySector_cont_bottom img {
  margin-top: 45px;
  float: right;
  width: 1000px;
  height: 520px;
}
.TechnologySector_cont_bottom_text {
  margin-top: -424px;
  float: left;
  width: 720px;
  height: 504px;
  background: #f8f8f8;
  z-index: 10;
}
.TechnologySector_cont_bottom_text div {
  margin-top: 36px;

  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.TechnologySector_cont_bottom_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 35px;
  margin-top: 24px;
}
.TechnologySector_cont_bottom_text_english {
  font-size: 70px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f8f8f8;
  line-height: 98px;
}
/* 教育 */
.Education {
  width: 100vw;
  height: 1741px;
  background: #f8f8f8;
}
.Education_cont {
  width: 1200px;
  margin: 0 auto;
  padding-top: 91px;
}
.Education_cont h1 {
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
}
.Education_item {
  margin-top: 45px;
}
.Education_item1 {
  display: flex;
}
.Education_item_text {
  background: #ffffff;
  width: 740px;
  height: 340px;
}
.Education_item_text p {
  margin-top: 72px;
  margin-left: 86px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: red;
  line-height: 33px;
}
.Education_item_text span {
  width: 568px;
  height: 140px;
  margin-top: 24px;
  margin-left: 86px;

  display: inline-block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
}
.Education_item1 {
  margin-bottom: 40px;
}
.Education_item1_block {
  width: 550px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 86px;
}

.Education_item1_block .div3 {
  margin: 40px 24px 38px 0;
  line-height: 40px;
  text-align: center;
  width: 172px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}
.Education_item1_block .div4 {
  margin-top: -10px;
}
.Education_item1_block .div5 {
  margin-top: -10px;
}

.Education_item1_block div {
  margin: 40px 24px 38px 0;
  line-height: 40px;

  text-align: center;
  width: 140px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}
/* 城市 */
.Citi {
  width: 100vw;
}
.Citi_cont {
  width: 1200px;
  margin: 0 auto;
}
.Citi_cont > h1 {
  text-align: center;
  margin-top: 83px;

  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
}
.Citi_cont_p {
  text-align: center;
  margin-top: 40px;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 32px;
}
.Citi_cont_item {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin-top: 64px;
}
.Citi_cont_item1 {
  width: 304px;
}

.Citi_cont_item1 p {
  margin: 24px 0;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 25px;
}
.Citi_cont_item1 span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
/* 华为 */
.HuaWei {
  width: 100vw;
  height: 809px;
  background: #ffffff;
}
.HuaWei_cont {
  padding-top: 80px;
  width: 1200px;
  margin: 0 auto;
}
.HuaWei_cont > h1 {
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
}
.HuaWei_cont_p {
  margin-top: 60px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
}

.HuaWei_cont_item {
  display: flex;
  margin-top: 81px;
  margin-left: 150px;
}

.HuaWei_cont_item_img img {
  text-align: center;
  width: 320px;
  height: 326px;
}
.HuaWei_cont_item_l1 {
  text-align: right;

  margin-right: 41px;
}
.HuaWei_cont_item_l1 p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
  margin-left: 180px;
}
.HuaWei_cont_item_l1 span {
  float: right;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.HuaWei_cont_item_l2 {
  text-align: right;
  margin-right: 41px;

  margin-top: 90px;
}
.HuaWei_cont_item_l2 p {
  margin-left: 180px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
  margin-left: 180px;
}
.HuaWei_cont_item_l2 span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.HuaWei_cont_item_r {
  margin-left: 16px;
}
.HuaWei_cont_item_r1 p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}
.HuaWei_cont_item_r1 span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.HuaWei_cont_item_r2 {
  margin-top: 90px;
}
.HuaWei_cont_item_r2 p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}
.HuaWei_cont_item_r2 span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.HuaWei_cont_item_c {
  margin-top: 41px;
  text-align: center;
}
.HuaWei_cont_item_c p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}
.HuaWei_cont_item_c span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
</style>
