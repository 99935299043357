<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>美舒家</span></div>
    </div>
    <!--  -->
    <div class="NewsDetails">
      <div class="NewsDetails_cont">
        <div class="NewsDetails_cont_title">
          <h1>美舒家空调清洗，守护柏林春天的闲暇时！</h1>
          <div class="NewsDetails_cont_title_span">
            <img src="../../assets/img/newsDetail/矩形.png" alt="" /><span
              >发布时间：2019-05-15&ensp;&ensp;
            </span>
            <img src="../../assets/img/newsDetail/矩形(1).png" alt="" /><span
              >浏览次数：447
            </span>
          </div>
        </div>
        <div class="NewsDetails_cont_text">
          <p>美舒家空调清洗，守护柏林春天的闲暇时光</p>
          <p>
            陕西中润恒通科技的美舒家家庭服务，这次可是大手笔呀！🎉与柏林春天社区合作，为我们的业主们提供免费空调清洗和家庭日常保洁服务，这可是守护我们闲暇时光的大好机会呢！💪
          </p>
          <p>
            得说，美舒家的服务真是超级棒👍。
            他们家的技术人员不仅专业，而且态度超好，让人感觉非常舒服。
            😊而且他们的清洁产品都是环保无害的，用起来放心又安心，对于我们这种追求生活质量的人来说，真的是太合适不过了！🍃
            我还记得上次他们来我家做保洁的时候，我家里的那个混乱啊...😫
            不过他们一来，就变得井井有条，整个家都焕然一新的感觉。
            🏡那干净整洁的样子，真的让我有种想要立马搬回家住的冲动😂。
          </p>
          <p>
            他们还提供了免费的空调清洗服务。
            炎炎夏日，有个干净的空调是多么的重要啊！😭
            美舒家的专业技术人员，不仅把空调清洗得干干净净，还教我一些日常保养的小技巧。
            真是受益匪浅啊！👏
            这次美舒家与柏林春天社区的合作活动，让我真切地感受到了他们的专业和贴心。
          </p>
          <p>
            如果你也是柏林春天社区的一员，那就千万不要错过这次的福利哦！😉
            #美舒家家庭服务# #免费空调清洗# #家庭日常保洁# #柏林春天社区# 🌟
          </p>
        </div>
        <div class="NewsDetails_cont_img">
          <img src="img/gh_bbf169b1ac92_860 (7).22f3beae.jpg" alt="" />
        </div>

        <div class="NewsDetails_cont_btm">
          <div>相关新闻</div>
          <p>更多 ></p>
        </div>
        <div class="NewsDetails_cont_btm2">
          <div class="NewsDetails_cont_btm2_1">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews0">
                发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/jinrong">
                美舒家品牌，因创新而卓越，焕新生活从此开始！...
              </a>
            </p>
          </div>
          <div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews1">
                清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务！
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/TradeNews3">
                美舒家西安华润二十四城半价油烟机清洗我来了...
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <applyFor></applyFor>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
import applyFor from "../../components/applyFor.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    applyFor,
    banner2,
  },
  data() {
    return {
      h1: "中润恒通新闻",
      Img1: require("../../assets/img/keji/矿山banner1.png"),

      p1: "更多最新精彩资讯，感受企业魅力",
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_cont_text div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}
.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}
.NewsDetails_cont_footer {
  padding: 0 40px;

  line-height: 68px;
  width: 1120px;
  height: 68px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>
