<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>美舒家</span></div>
    </div>
    <!--  -->
    <div class="NewsDetails">
      <div class="NewsDetails_cont">
        <div class="NewsDetails_cont_title">
          <h1>清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务！</h1>
          <div class="NewsDetails_cont_title_span">
            <img src="../../assets/img/newsDetail/矩形.png" alt="" /><span
              >发布时间：2019-05-15&ensp;&ensp;
            </span>
            <img src="../../assets/img/newsDetail/矩形(1).png" alt="" /><span
              >浏览次数：677
            </span>
          </div>
        </div>
        <div class="NewsDetails_cont_text">
          <p>清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务！</p>
          <p>
            清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务
            美舒家，作为一家专注于家居舒适生活的企业，一直致力于为消费者提供优质的产品和服务。
            近期，我们推出了一项特别的活动——西安国润城前10位业主享受免费空调清洗。
            这一活动的推出，旨在让更多的家庭体验到美舒家的专业服务，同时也是我们对西安国润城业主的一份特别回馈。
          </p>
          <p>
            在这个炎热的夏天，空调已经成为了我们生活中必不可少的家电之一。
            然而，长时间使用空调会导致灰尘、细菌等污垢堆积在空调内部，影响空调的制冷效果和空气质量。
            为了解决这个问题，美舒家特意推出了免费空调清洗活动。
          </p>
          <p>
            活动开始前，我们的专业团队就已经开始了紧锣密鼓的准备工作。
            他们首先对参与活动的业主进行了详细的调查，了解每位业主的空调品牌、型号和使用情况，以便为他们提供更加贴心、专业的服务。
            同时，我们还为业主们准备了丰富的互动环节，让他们在享受服务的同时，也能参与到我们的活动中来。
          </p>
          <p>
            活动当天，阳光明媚，微风拂面。
            我们的专业团队早早地来到了西安国润城，迎接前来参加活动的业主们。
            为了让业主们更加方便地找到我们的服务点，我们在小区内设置了醒目的指示牌，并安排了专人进行引导。
            此外，我们还为每位业主准备了精美的礼品，以表达我们对他们的感谢和欢迎。
          </p>
          <p>
            在活动现场，我们的专业技师们耐心地为业主们讲解空调清洗的重要性和具体步骤。
            他们一边操作着专业的清洗设备，一边与业主们亲切交流，解答他们在使用空调过程中遇到的各种问题。
            看到自己的空调经过清洗后焕然一新，业主们纷纷表示对我们的服务非常满意。
          </p>
          <p>
            除了专业的空调清洗服务外，我们还为业主们准备了一场别开生面的互动环节。
            在现场，我们设置了多个游戏区域，让业主们可以带着孩子一起参与游戏，增进亲子关系。
            此外，我们还邀请了专业的摄影师为业主们拍摄全家福，记录下这个美好的瞬间。
            这些温馨的画面，成为了我们活动中一道亮丽的风景线。
          </p>
          <p>
            随着活动的深入，越来越多的业主加入到我们的活动中来。
            他们在享受我们提供的优质服务的同时，也结识了更多的邻居和朋友。
            在这里，他们共同分享着生活的喜悦和感动，感受着社区大家庭的温暖。
            而这也正是我们所期望看到的——通过我们的活动，让更多的家庭感受到美舒家的关爱，让社区居民之间的关系更加紧密。
          </p>
          <p>
            回顾这次活动，我们深感荣幸能够为西安国润城的业主们带来这样一次难忘的体验。
            通过免费的空调清洗服务，我们不仅提高了业主们的生活质量，也让他们深刻体会到了美舒家的用心和专业。
            同时，这次活动也让我们更加坚信：只有真正关注消费者需求，用心去服务每一个家庭，才能赢得市场的认可和支持。
          </p>
          <p>
            展望未来，美舒家将继续秉承“以人为本、服务至上”的理念，为广大消费者提供更多优质、专业的家居服务。
            我们将不断创新服务模式，拓展业务领域，努力成为家居舒适生活的引领者。
            同时，我们也期待与更多的社区、物业公司合作，共同打造一个美好、和谐的居住环境。
          </p>
          <p>
            美舒家-西安国润城前10位业主享受免费空调清洗活动取得了圆满的成功。
            这次活动不仅提升了业主们的生活质量，也让他们感受到了美舒家的用心和专业。
            我们相信，在未来的日子里，美舒家将携手更多的家庭，共同创造美好的家居生活。
          </p>
          <p>在这个充满爱与关怀的时代，让我们一起携手前行，共创美好未来！</p>
        </div>
        <div class="NewsDetails_cont_img">
          <img src="img/gh_bbf169b1ac92_860 (7).22f3beae.jpg" alt="" />
        </div>

        <div class="NewsDetails_cont_btm">
          <div>相关新闻</div>
          <p>更多 ></p>
        </div>
        <div class="NewsDetails_cont_btm2">
          <div class="NewsDetails_cont_btm2_1">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews0">
                发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/jinrong">
                美舒家品牌，因创新而卓越，焕新生活从此开始！...
              </a>
            </p>
          </div>
          <div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews1">
                清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务！
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/TradeNews3">
                美舒家西安华润二十四城半价油烟机清洗我来了...
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <applyFor></applyFor>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
import applyFor from "../../components/applyFor.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    applyFor,
    banner2,
  },
  data() {
    return {
      h1: "中润恒通新闻",
      Img1: require("../../assets/img/keji/矿山banner1.png"),

      p1: "更多最新精彩资讯，感受企业魅力",
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_cont_text div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}
.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}
.NewsDetails_cont_footer {
  padding: 0 40px;

  line-height: 68px;
  width: 1120px;
  height: 68px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>
