<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>美舒家</span></div>
    </div>
    <!--  -->
    <div class="NewsDetails">
      <div class="NewsDetails_cont">
        <div class="NewsDetails_cont_title">
          <h1>西安华润二十四城半价清洗油烟机来了</h1>
          <div class="NewsDetails_cont_title_span">
            <img src="../../assets/img/newsDetail/矩形.png" alt="" /><span
              >发布时间：2019-05-15&ensp;&ensp;
            </span>
            <img src="../../assets/img/newsDetail/矩形(1).png" alt="" /><span
              >浏览次数：447
            </span>
          </div>
        </div>
        <div class="NewsDetails_cont_text">
          <p>
            在繁忙的现代生活中，厨房油烟成为家庭主妇和烹饪爱好者面临的一个普遍问题。
            油烟不仅影响居住环境的清洁，还可能对居住者的健康造成潜在威胁。
            针对这一痛点，美舒家特别推出了一项专为西安华润二十四城业主量身打造的服务——半价清洗油烟机服务，旨在为业主们营造一个更健康、更干净的生活环境。
          </p>
          <p>**一、专业团队，细致服务**</p>
          <p>
            美舒家拥有一支专业的清洗团队，他们经过严格的培训，掌握了高效安全的清洗技术。
            从拆卸到清洗再到重新装配，每一步都严格遵循操作流程，确保每一位业主的油烟机都能得到彻底的清洁。
            此外，美舒家的服务人员态度亲切，注重细节，力求在每一次服务中都能让业主感受到美舒家的用心和专业。
          </p>
          <p>**二、深层清洁，效果显著**</p>
          <p>
            传统的油烟机清洗方法往往只能清除表面油垢，而美舒家采用的深层清洁技术能深入油烟机内部，彻底去除长期积累的油渍和异味。
            通过专业的清洁剂和设备，不仅能够恢复油烟机的排烟效率，还能有效延长其使用寿命。
            许多业主反映，经过美舒家的专业清洗后，厨房的空气变得更加清新，烹饪时的油烟问题得到了明显改善。
          </p>
          <p>**三、价格优惠，性价比高**</p>
          <p>
            为了让更多的业主享受到这项优质服务，美舒家特别推出了半价清洗油烟机的活动。
            这一优惠活动的推出，不仅减轻了业主的家庭负担，也体现了美舒家对华润二十四城业主的特别关怀。
            相比市场上同类产品，美舒家的价格更为亲民，但服务质量却丝毫不打折，真正做到了物超所值。
          </p>
          <p>**四、方便快捷，预约灵活**</p>
          <p>
            为了方便业主们的生活，美舒家提供了多种预约方式，包括电话预约、在线预约等。
            一旦预约成功，美舒家的服务团队会在约定的时间内准时到达，尽量减少对业主日常生活的影响。
            整个过程快速而高效，不会影响业主的正常生活节奏。
            **五、环保理念，健康生活**
            美舒家在提供清洗服务的同时，也倡导环保和健康的生活方式。
            使用的清洁剂均为环保型产品，既安全又高效，既能清洁油污，又能保护环境。
            美舒家希望通过自己的专业服务，帮助业主们建立一个更加干净、健康的生活环境。
            结语：
            美舒家半价清洗油烟机服务的推出，无疑为西安华润二十四城的业主们带来了一场及时雨。
            不仅解决了厨房油烟的问题，还以优惠的价格和专业的服务赢得了业主们的广泛好评。
            如果您也是华润二十四城的业主，不妨尝试一下美舒家的这项服务，让您的厨房焕然一新，享受健康无油烟的烹饪乐趣。
          </p>
        </div>
        <div class="NewsDetails_cont_img">
          <img src="img/gh_bbf169b1ac92_860 (7).22f3beae.jpg" alt="" />
        </div>

        <div class="NewsDetails_cont_btm">
          <div>相关新闻</div>
          <p>更多 ></p>
        </div>
        <div class="NewsDetails_cont_btm2">
          <div class="NewsDetails_cont_btm2_1">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews0">
                发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/jinrong">
                美舒家品牌，因创新而卓越，焕新生活从此开始！...
              </a>
            </p>
          </div>
          <div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews1">
                清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务！
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/TradeNews3">
                美舒家西安华润二十四城半价油烟机清洗我来了...
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <applyFor></applyFor>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
import applyFor from "../../components/applyFor.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
    applyFor,
  },
  data() {
    return {
      h1: "中润恒通新闻",
      Img1: require("../../assets/img/keji/矿山banner1.png"),

      p1: "更多最新精彩资讯，感受企业魅力",
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_cont_text div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}
.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}
.NewsDetails_cont_footer {
  padding: 0 40px;

  line-height: 68px;
  width: 1120px;
  height: 68px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>
