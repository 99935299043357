<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>美舒家家庭服务</span></div>
    </div>
    <!-- 家政业务 -->
    <div class="Money">
      <div class="Money_cont">
        <h1>美舒家家庭服务</h1>
        <div class="Money_cont_top">
          <p>
            中润恒通公司旗下的美舒家家政服务品牌，以其卓越的服务品质和全方位的家庭服务解决方案，在业界享有盛誉。该品牌深度聚焦于满足现代家庭多元化、个性化的生活需求，涵盖了日常保洁、家务助理、母婴护理、老人陪护、家庭烹饪、家庭教育指导等多元化的家庭服务项目。美舒家家政服务团队由一批专业培训、经验丰富、服务贴心的人员组成，他们以客户满意度为核心，致力于为每一个家庭创造舒适、便捷、和谐的生活环境，让每一位用户都能享受到高品质的家庭生活体验。通过持续创新与优化服务模式，中润恒通公司的美舒家品牌正在逐步树立起行业内的服务标杆，成为众多家庭信赖并首选的家庭服务品牌。
          </p>

          <img src="../assets/img/jituan3.png" alt="" />
        </div>
        <div class="Money_cont_c">
          <div class="Money_cont_c_img">
            <img src="../assets/img/gh_bbf169b1ac92_860 (7).jpg" alt="" />
            <p>一站式服务</p>
          </div>
          <div class="Money_cont_c_text">
            <p>
              致力于为广大家庭客户提供全面周到、专业高效的一站式家政服务体系，我们涵盖了多个家居生活服务领域。在基础的设施维护方面，我们提供细致入微的水电维修服务，包括但不限于电路检修、水管更换与维护等；同时，我们也精通各类管道系统的排查与修复工作，无论是厨房、卫生间还是暖气系统，都能确保其顺畅运作。
              此外，我们的专业技术团队还提供全方位的电器维修服务，美舒家品牌服务主要包含各类建筑的外部清洁，外墙清洗，外墙刷漆，外墙修补，幕墙清洗，房屋翻修，内部装修，维修，保洁及各类型电器和设备的安装-墙面清洗，墙面刷漆，房屋装修，房屋装修维修，保洁，垃圾清运，家电清洗维修，水电维修，
              砸墙，改水电，门窗定制，窗纱定制，摄像头，弱点，监控，贴瓷砖，批腻子，墙面粉刷，填缝，擦窗子，擦玻璃，精细擦玻璃，保洁，开荒保洁，厨房蒸洗，卫生间蒸洗，全屋打扫，深度保洁，灯具安装，灯具清洗，沙发清洗，地毯清洗，床品除螨，床品清洗，窗帘清洗，
              日常保洁,搬家，整理收纳，油烟机清洗，热水器清洗，空调清洗，洗衣机清洗，冰箱清洗，油烟机维修，冰箱维修，空调维修，洗衣机维修，燃气灶安装维修，换气扇安装，花洒安装，水龙头安装，洗面池安装，桌椅安装，木床拆卸安装，净水设备安装，管道疏通，防水补漏，墙面翻新，
              电脑办公设备，打印机安装维修，婚礼策划，红包喜事策划，视频录制等
            </p>
          </div>
        </div>
        <div class="Money_cont_b">
          <div class="Money_cont_b_item3 Money_cont_b_item4">
            <div class="Money_cont_b_item3_text">
              <p>
                同时，我们还提供专业的消杀服务，采用安全高效的消毒灭菌手段，消除室内的害虫及微生物，确保家庭环境的卫生安全。
                更进一步，我们理解到现代家庭生活的多元化需求，因此还提供了贴心的生活协助服务，如代购代办、老人陪护、接送孩子上下学等，以解决家庭日常琐事，让客户有更多时间享受生活。另外，我们拥有一支高素质的家教团队，提供一对一或小班制的个性化家教服务，涵盖各年龄段、各学科领域，旨在助力孩子的学业进步，满足家庭教育需求。
                总的来说，我们致力于通过以上各项服务，从细微之处着手，全方位提升家庭生活质量，让每一位客户都能享受到专业、便捷、贴心的一站式家政服务体验。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <applyFor></applyFor>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import applyFor from "../components/applyFor.vue";

export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
    applyFor,
  },
  data() {
    return {
      h1: "美舒家",
      Img1: require("../assets/img/keji/矿山banner1.png"),
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 金融 */
.Money_cont {
  width: 1200px;
  margin: 0 auto;
}
.Money_cont > h1 {
  margin: 65px 0 45px 0;
  text-align: center;
}
.Money_cont_top {
  display: flex;
  justify-content: space-between;
}
.Money_cont_top > p {
  width: 600px;
  height: 360px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
}
/* 中 */
.Money_cont_c {
  display: flex;
  margin-top: 80px;
  width: 1200px;
  height: 440px;
}
.Money_cont_c_img {
  text-align: center;

  width: 322px;
  height: 440px;
  /* background-image: url("../assets/img/jinrong/摄图网_500476224_banner_看得见的未来（非企业商用）.png"); */
}
.Money_cont_c_img p {
  margin-top: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}
.Money_cont_c_img img {
  margin-top: 138px;
}
.Money_cont_c_text {
  width: 878px;
  height: 440px;
  background: #f8f8f8;
}
.Money_cont_c_text > p {
  width: 769px;
  height: 96px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 32px;
  margin: 40px 0 40px 40px;
}
.Money_cont_c_text_b {
  margin-bottom: 30px;
  margin-left: 40px;
}
.Money_cont_c_text_b span {
  color: blue;
}
.Money_cont_b_item1 {
  display: flex;
  margin-top: 40px;
}
.Money_cont_b_img {
  text-align: center;
  background-image: url("../assets/img/jinrong/摄图网_500476224_banner_看得见的未来（非企业商用）.png");
  width: 322px;
  height: 300px;
}
.Money_cont_b_img p {
  margin-top: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}
.Money_cont_b_img img {
  margin-top: 67px;
}
.Money_cont_b_text {
  width: 878px;
  height: 300px;
  background: #f8f8f8;
}
.Money_cont_b_text p {
  margin: 50px 0 0 40px;
  width: 769px;
  height: 200px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
}
/* item2 */
.Money_cont_b_item2 {
  display: flex;
  margin-top: 40px;
}
.Money_cont_b_item2_img {
  text-align: center;
  background-image: url("../assets/img/jinrong/摄图网_500476224_banner_看得见的未来（非企业商用）.png");
  width: 322px;
  height: 300px;
}
.Money_cont_b_item2_img p {
  margin-top: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}
.Money_cont_b_item2_img img {
  margin-top: 67px;
}
.Money_cont_b_item2_text {
  width: 878px;
  height: 300px;
  background: #f8f8f8;
}
.Money_cont_b_item2_text p {
  margin: 50px 0 0 40px;
  width: 769px;
  height: 200px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
}

/* item3 */
.Money_cont_b_item3 {
  display: flex;

  margin: 40px 0 80px 0;
}
.Money_cont_b_item3_img {
  text-align: center;
  background-image: url("../assets/img/jinrong/摄图网_401914220_banner_3D创意金融（非企业商用）.png");
  width: 322px;
  height: 300px;
}
.Money_cont_b_item3_img p {
  margin-top: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}
.Money_cont_b_item3_img img {
  margin-top: 67px;
}
.Money_cont_b_item3_text {
  width: 878px;
  height: 300px;
  background: #f8f8f8;
}
.Money_cont_b_item3_text p {
  margin: 50px 0 0 40px;
  width: 769px;
  height: 200px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
}
</style>
