<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>行业资讯</span></div>
    </div>
    <!--  -->
    <div class="NewsDetails">
      <div class="NewsDetails_cont">
        <div class="NewsDetails_cont_title">
          <h1>美舒家品牌所处行业分析</h1>
          <div class="NewsDetails_cont_title_span">
            <img src="../../assets/img/newsDetail/矩形.png" alt="" /><span
              >发布时间：2019-11-15&ensp;&ensp;
            </span>
            <img src="../../assets/img/newsDetail/矩形(1).png" alt="" /><span
              >浏览次数：12345
            </span>
          </div>
        </div>
        <div class="NewsDetails_cont_text">
          <p>美舒家品牌所处行业分析</p>

          <p>基本术语</p>
          <p>
            美舒家品牌横跨了家政行业、家庭服务行业以及企业软硬件服务行业，涉及的主要基本术语包括：
          </p>

          <p>
            家政服务：涵盖家庭日常保洁、保姆月嫂、家电维修保养等各类家庭生活辅助服务。
          </p>
          <p>
            家庭服务行业：提供满足家庭生活需求的各种专业服务，如家庭餐饮定制、家居装修维护等。
          </p>
          <p>
            企业软硬件服务：为企业提供信息化解决方案，包括但不限于企业管理系统、办公自动化软件、网络设备供应与安装、监控安防系统集成等。
          </p>
          <p>行业规模</p>
          <p>
            由于美舒家涉及多个领域，暂无法提供具体单一行业的规模数据。但根据公开资料，中国家政服务市场规模持续扩大，企业信息化服务及餐饮服务市场也呈现稳健增长态势。
          </p>

          <p>生命周期与发展历史</p>
          <p>
            家政服务行业在中国正处于快速发展阶段，随着消费升级和人口老龄化趋势，市场需求日益旺盛；家庭服务行业随生活方式变化而不断创新，尤其在个性化、品质化服务方面有较大发展空间；企业软硬件服务行业已进入成熟期，数字化转型成为企业核心竞争力之一。
          </p>
          <p>
            美舒家品牌凭借IT行业资深专家团队，结合餐饮、装修及信息服务领域的丰富经验，自成立以来便锐意进取，成功打造了一系列多元化、高品质的服务案例，见证了相关行业的。
          </p>
          <p>盈利模式</p>
          <p>美舒家品牌的盈利模式包括：</p>

          <p>家政服务和家庭服务：通过提供专业服务收取服务费用；</p>
          <p>
            企业软硬件服务：销售自主研发的软硬件产品，或提供定制化的信息化解决方案，并由此获取项目实施费用和技术支持服务费。
          </p>
          <p>供应商</p>
          <p>
            美舒家品牌与其上游客户合作紧密，主要供应商包括知名科技企业如唯品会、海康威视、腾讯、阿里巴巴、TPLINK、GOODVIEW等，涵盖了硬件设备、软件开发、安全监控等多个领域。
          </p>
          <p>用户群体</p>
          <p>
            美舒家品牌的用户群体广泛，既包括需要家政服务的家庭用户，也有对餐饮、装修服务有需求的企业和个人，同时还有众多寻求企业信息化升级服务的企事业单位。
          </p>

          <p>竞争格局</p>
          <p>
            在家政服务、家庭服务领域，美舒家面临来自传统家政公司、新兴互联网家政平台的竞争；在企业软硬件服务市场，则需与众多IT解决方案提供商争夺市场份额。
          </p>
          <p>监管政策</p>
          <p>各行业均有相应的监管政策：</p>

          <p>
            家政服务行业受劳动法规、消费者权益保护法等相关法律法规约束，需确保服务质量与员工权益；
          </p>
          <p>餐饮服务行业需遵守食品安全法、餐饮服务许可管理办法等规定；</p>
          <p>
            企业软硬件服务行业则需关注网络安全法、信息技术服务管理规范等政策，确保提供的产品和服务符合国家信息安全标准。
          </p>
        </div>
        <div class="NewsDetails_cont_img">
          <img
          src="img/gh_bbf169b1ac92_860 (7).22f3beae.jpg"
            alt=""
          />
        </div>
        <Next ref="childData"></Next>
        <div class="NewsDetails_cont_btm">
          <div>相关新闻</div>
          <p>更多 ></p>
        </div>
        <div class="NewsDetails_cont_btm2">
          <div class="NewsDetails_cont_btm2_1">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews0">
                发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/jinrong">
                美舒家品牌，因创新而卓越，焕新生活从此开始！...
              </a>
            </p>
          </div>
          <div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews1">
                清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务！
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/TradeNews3">
                美舒家西安华润二十四城半价油烟机清洗我来了...
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <applyFor></applyFor>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
import applyFor from "../../components/applyFor.vue";
import Next from "../../components/NextLastArtcle.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
    applyFor,
    Next,
  },
  data() {
    return {
      h1: "中润恒通新闻",
      Img1: require("../../assets/img/keji/矿山banner1.png"),
      artcleID: 2,
      p1: "更多最新精彩资讯，感受企业魅力",
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_cont_text div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}
.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}
.NewsDetails_cont_footer {
  padding: 0 40px;

  line-height: 68px;
  width: 1120px;
  height: 68px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>
