import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        title: '',
        pageCount: 8,
        selected: 0,
    },
    mutations: {},
    actions: {},
    modules: {}
})