import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import jituangaikuo from "../views/jituangaikuo.vue";
import keji from "../views/keji.vue";
import youjiao from "../views/youjiao.vue";
import jinrong from "../views/jinrong.vue";
import zhijiao from "../views/zhijiao.vue";
import dianshang from "../views/dianshang.vue";
import news from "../views/news.vue";
import GroupNews from "../views/News/GroupNews.vue";
import MediaCoverage from "../views/News/MediaCoverage.vue";
import TradeNews from "../views/News/TradeNews.vue";
import newsDetail from "../views/newsxiangqing.vue";
import newsDetail1 from "../views/NewsDetals/newsxiangqing1.vue";
import newsDetail2 from "../views/NewsDetals/newsxiangqing2.vue";
import MediaCoverage0 from "../views/NewsDetals/MediaCoverage0.vue";
import MediaCoverage1 from "../views/NewsDetals/MediaCoverage1.vue";
import TradeNews0 from "../views/NewsDetals/TradeNews0.vue";
import TradeNews1 from "../views/NewsDetals/TradeNews1.vue";
import TradeNews2 from "../views/NewsDetals/TradeNews2.vue";
import TradeNews3 from "../views/NewsDetals/TradeNews3.vue";
import zhaopinxinxi0 from "../views/zhaopinxinxi0.vue";
import zhaopinxinxi1 from "../views/Recruit-info/zhaopinxinxi1.vue";
import lianxi from "../views/lianxiwomen.vue";
import zhaopinxiangqing0_1 from "../views/Recruitment-details/zhaopinxiangqing0-1.vue";
import zhaopinxiangqing0_0 from "../views/Recruitment-details/zhaopinxiangqing0-0.vue";
import zhaopinxiangqing0_2 from "../views/Recruitment-details/zhaopinxiangqing0-2.vue";
import zhaopinxiangqing0_3 from "../views/Recruitment-details/zhaopinxiangqing0-3.vue";
import zhaopinxiangqing0_4 from "../views/Recruitment-details/zhaopinxiangqing0-4.vue";
import zhaopinxiangqing0_5 from "../views/Recruitment-details/zhaopinxiangqing0-5.vue";
import zhaopinxiangqing0_6 from "../views/Recruitment-details/zhaopinxiangqing0-6.vue";
import zhaopinxiangqing0_7 from "../views/Recruitment-details/zhaopinxiangqing0-7.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "index",
        component: index,
        meta: {
            title: "装修保洁清洗,保洁公司,家政保洁价格,保洁公司排名,美舒家",
            description: "美舒家,专注打造高家庭服务品牌。主营：装修,外墙清洗刷漆,房屋翻修,防水补漏,保洁,电器清洗,垃圾清运,水电维修,砸墙,改水电,摄像头,弱电,监控,贴瓷砖,封阳台,墙面粉刷,填缝,换纱窗,擦玻璃,开荒保洁,全屋打扫,深度保洁,灯具安装,灯具清洗,沙发清洗,地毯清洗,床品除螨,床品清洗,窗帘清洗,搬家,收纳整理,油烟机清洗,油烟管道清洗,油烟净化器清洗,热水器清洗,空调清洗,洗衣机清洗,冰箱清洗,油烟机维修,冰箱维修,空调维修,洗衣机维修",
            keywords: "装修,保洁清洗,保洁公司,家政保洁价格,保洁公司排名",
            copyright: "陕西中润恒通科技有限责任公司",
            author: "美舒家"

        }
    },
    {
        path: "/jituangaikuo",
        name: "jituangaikuo",
        component: jituangaikuo,
        meta: {
            title: "装修保洁清洗,保洁公司,家政保洁价格,保洁公司排名,美舒家",
        },
    },
    {
        path: "/keji",
        name: "keji",
        component: keji,
        meta: {
            title: "保洁，消杀，开业策划，品牌筹备，设备维修，强电弱电监控，外墙清洗，美舒家",
        },
    },
    {
        path: "/youjiao",
        name: "youjiao",
        component: youjiao,
        meta: {
            title: "宣传片，菜单制作，菜品拍摄，品牌筹备，抖音代运营，门头制作，广告牌物料，美舒家",
        },
    },
    {
        path: "/jinrong",
        name: "jinrong",
        component: jinrong,
        meta: {
            title: "装修保洁清洗,保洁公司,家政保洁价格,保洁公司排名,美舒家家政服务平台",
        },
    },
    {
        path: "/zhijiao",
        name: "zhijiao",
        component: zhijiao,
        meta: {
            title: "系统维护，电脑监控，IT运维，收银系统，库存管理，会员管理，美团，美舒家",
        },
    },
    {
        path: "/dianshang",
        name: "dianshang",
        component: dianshang,
        meta: {
            title: "系统维护，电脑监控，IT运维，收银系统，库存管理，会员管理，美团，美舒家",
        },
    },
    {
        path: "/xinwen",
        name: "xinwen",
        component: news,
        children: [{
                path: "",
                component: GroupNews,
                name: "GroupNews",
                meta: {
                    title: "消杀，消杀公司，日常消杀，美舒家西安保洁服务平台",
                },
            },
            {
                path: "MediaCoverage",
                component: MediaCoverage,
                name: "MediaCoverage",
                meta: {
                    title: "媒体报道",
                },
            },
            {
                path: "TradeNews",
                name: "TradeNews",
                component: TradeNews,
                meta: {
                    title: "翻修，装修，贴磁砖勾缝，墙面粉刷，窗帘，水电，美舒家装修服务平台",
                },
            },
        ],
        meta: {
            title: "保洁，拍摄，家政，宣传片，餐单，系统运维",
        },
    },
    {
        path: "/newsxiangqing0",
        name: "newsDetal",
        component: newsDetail,
        meta: {
            title: "保洁，消杀，开业策划，品牌筹备，设备维修，强电弱电监控，外墙清洗，美舒家",
        },
    },
    {
        path: "/newsxiangqing1",
        name: "newsDetal1",
        component: newsDetail1,
        meta: {
            title: "保洁，消杀，开业策划，品牌筹备，设备维修，强电弱电监控，外墙清洗，美舒家",
        },
    },
    {
        path: "/newsxiangqing2",
        name: "newsDetal2",
        component: newsDetail2,
        meta: {
            title: "保洁，消杀，开业策划，品牌筹备，设备维修，强电弱电监控，外墙清洗，美舒家",
        },
    },
    {
        path: "/MediaCoverage0",
        name: "MediaCoverage0",
        component: MediaCoverage0,
        meta: {
            title: "翻修，装修，贴磁砖勾缝，墙面粉刷，窗帘，水电，美舒家装修服务平台",
        },
    },
    {
        path: "/MediaCoverage1",
        name: "MediaCoverage1",
        component: MediaCoverage1,
        meta: {
            title: "翻修，装修，贴磁砖勾缝，墙面粉刷，窗帘，水电，美舒家装修服务平台",
        },
    },
    {
        path: "/TradeNews0",
        name: "TradeNews0",
        component: TradeNews0,
        meta: {
            title: "空调清洗,油烟机清洗,洗衣机清洗,空调清洗价格, 沙发清洗，美舒家家政服务平台，美舒家携手保利金香槟",
        },
    },
    {
        path: "/TradeNews1",
        name: "TradeNews1",
        component: TradeNews1,
        meta: {
            title: "空调清洗,油烟机清洗,洗衣机清洗,空调清洗价格, 沙发清洗，美舒家家政服务平台，清凉一夏国润城",
        },
    },
    {
        path: "/TradeNews2",
        name: "TradeNews2",
        component: TradeNews2,
        meta: {
            title: "空调清洗,油烟机清洗,洗衣机清洗,空调清洗价格, 沙发清洗，美舒家家政服务平台，免费空调清洗守护柏林时光",
        },
    },
    {
        path: "/TradeNews3",
        name: "TradeNews3",
        component: TradeNews3,
        meta: {
            title: "空调清洗,油烟机清洗,洗衣机清洗,空调清洗价格, 沙发清洗，美舒家家政服务平台，华润二十四城送半价油烟机清洗",
        },
    },
    {
        path: "/zhaopinxinxi0",
        name: "zhaopinxinxi0",
        component: zhaopinxinxi0,
        meta: {
            title: "美舒家招聘",
        },
    },
    {
        path: "/zhaopinxinxi1",
        name: "zhaopinxinxi1",
        component: zhaopinxinxi1,
        meta: {
            title: "招聘信息1",
        },
    },
    {
        path: "/lianxi",
        name: "lianxi",
        component: lianxi,
        meta: {
            title: "联系美舒家",
        },
    },
    {
        path: "/zhaopinxiangqing0_0",
        name: "zhaopinxiangqing0_0",
        component: zhaopinxiangqing0_0,
        meta: {
            title: "美舒家，行政招聘，抖音运营",
        },
    },
    {
        path: "/zhaopinxiangqing0_1",
        name: "zhaopinxiangqing0_1",
        component: zhaopinxiangqing0_1,
        meta: {
            title: "美舒家，网络工程师招聘，接单，临时工，靠谱的家政保洁，招聘",
        },
    },
    {
        path: "/zhaopinxiangqing0_2",
        name: "zhaopinxiangqing0_2",
        component: zhaopinxiangqing0_2,
        meta: {
            title: "美舒家，家电清洗，接单，海量西安人才招聘信息，招聘",
        },
    },
    {
        path: "/zhaopinxiangqing0_3",
        name: "zhaopinxiangqing0_3",
        component: zhaopinxiangqing0_3,
        meta: {
            title: "美舒家，家电清洗，接单，海量西安人才招聘信息，招聘",
        },
    },
    {
        path: "/zhaopinxiangqing0_4",
        name: "zhaopinxiangqing0_4",
        component: zhaopinxiangqing0_4,
        meta: {
            title: "美舒家，家电清洗，接单，海量西安人才招聘信息，弱电工程师招聘",
        },
    },
    {
        path: "/zhaopinxiangqing0_5",
        name: "zhaopinxiangqing0_5",
        component: zhaopinxiangqing0_5,
        meta: {
            title: "美舒家，家电维修工程师，招聘，西安人才招聘信息",
        },
    },
    {
        path: "/zhaopinxiangqing0_6",
        name: "zhaopinxiangqing0_6",
        component: zhaopinxiangqing0_6,
        meta: {
            title: "美舒家，工程师，招聘，西安人才招聘信息",
        },
    },
    {
        path: "/zhaopinxiangqing0_7",
        name: "zhaopinxiangqing0_7",
        component: zhaopinxiangqing0_7,
        meta: {
            title: "美舒家，工程师，招聘，西安人才招聘信，抖音/电商运营工程师",
        },
    }
];
// title

const router = new VueRouter({
    routes,
    // 跳转页面后 回到顶部
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        };
    },
});

export default router;

//title
const defaultTitle = "美舒家";
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : defaultTitle;
    next();
});