<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>美舒家案例</span></div>
    </div>
    <!--  -->
    <div class="NewsDetails">
      <div class="NewsDetails_cont">
        <div class="NewsDetails_cont_title">
          <h1>美舒家守护保利金香槟业主的闲暇时光：免费空调清洗</h1>
          <div class="NewsDetails_cont_title_span">
            <img src="../../assets/img/newsDetail/矩形.png" alt="" /><span
              >发布时间：2019-11-15&ensp;&ensp;
            </span>
            <img src="../../assets/img/newsDetail/矩形(1).png" alt="" /><span
              >浏览次数：290
            </span>
          </div>
        </div>
        <div class="NewsDetails_cont_text">
          <p>美舒家案例-守护保利金香槟业主的闲暇时光：免费空调清洗季</p>
          <p>
            美舒家守护闲暇时光：免费空调清洗来袭！
            在西安保利金香槟社区，美舒家家庭服务品牌以其专业和周到的服务赢得了业主们的信任和好评。
          </p>
          <p>
            近日，美舒家携手社区管理方，为业主们提供了一项贴心的服务——免费空调清洗及家庭日常保洁服务活动。
            这一举措不仅提升了居民的生活品质，也体现了美舒家对业主闲暇时光的深切关怀。
            陕西中润恒通科技有限责任公司旗下美舒家家庭服务品牌作为新型家庭服务企业，已经迅速成长为本地家政服务行业的佼佼者。
            美舒家品牌以“为家庭创造舒适生活”为使命，提供从专业保洁、清洗消毒到健康咨询、母婴护理等一系列全面的家庭服务。
            此次活动，正是美舒家秉承“以人为本，服务至上”理念的具体实践。
            活动现场，美舒家的专业技术人员耐心地为业主讲解空调使用与保养的小知识，同时进行细致的清洗服务。
            不仅如此，他们还对家中的其他家电进行了检查和维护指导，确保了家电的安全使用，同时也延长了家电的使用寿命。
            这种细致入微的服务，让业主们感受到了美舒家的温暖和专业。
            除了免费清洗空调之外，美舒家还为业主提供了家庭日常保洁服务。
          </p>
          <p>
            在忙碌的现代生活中，能够有一个干净舒适的居住环境，对于提升生活品质来说至关重要。
            美舒家的专业保洁团队，使用环保高效的清洁用品，针对家庭中的不同区域，采取了分区清洁的方法，不仅清洁彻底，而且兼顾了家庭成员的健康。
            此次与西安保利金香槟社区的合作，不仅仅是一次简单的服务活动，更是美舒家深耕社区服务、积极履行社会责任的具体体现。
            通过这样的活动，美舒家不仅将高品质的家政服务带给了更多的家庭，也为促进社区和谐发展、构建温馨幸福的居住环境做出了积极的贡献。
          </p>
          <p>
            美舒家的故事还在继续，在未来的日子里，他们将继续以专业的服务、真诚的态度，守护每一个家庭的美好生活。
            正如美舒家一直强调的那样：“我们不仅仅是家政服务的提供者，更是每个家庭幸福生活的守护者。
            在推进企业数字化转型和家政服务行业升级的过程中，美舒家更是积极响应国家发展战略，积极推动数字经济的发展，努力构建一个融合线上线下、高效协同、开放共享的新型家政服务体系，旨在让每一个选择美舒家的家庭都能享受到科技带来的美好生活，共同见证并参与这场由创新引发的、关乎未来生活方式的伟大变革。
          </p>
        </div>
        <div class="NewsDetails_cont_img">
          <img src="img/gh_bbf169b1ac92_860 (7).22f3beae.jpg" alt="" />
        </div>
        <div class="NewsDetails_cont_img">
          <img src="img/ewm2.340f4b50.png" alt="" />
        </div>

        <div class="NewsDetails_cont_btm">
          <div>相关新闻</div>
          <p>更多 ></p>
        </div>
        <div class="NewsDetails_cont_btm2">
          <div class="NewsDetails_cont_btm2_1">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews0">
                发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/jinrong">
                美舒家品牌，因创新而卓越，焕新生活从此开始！...
              </a>
            </p>
          </div>
          <div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews1">
                清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务！
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/TradeNews3">
                美舒家西安华润二十四城半价油烟机清洗我来了...
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <applyFor></applyFor>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
import applyFor from "../../components/applyFor.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
    applyFor,
  },
  data() {
    return {
      h1: "中润恒通新闻",
      Img1: require("../../assets/img/keji/矿山banner1.png"),

      p1: "更多最新精彩资讯，感受企业魅力",
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_cont_text div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}
.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}
.NewsDetails_cont_footer {
  padding: 0 40px;

  line-height: 68px;
  width: 1120px;
  height: 68px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>
