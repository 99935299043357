<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>影视拍摄</span></div>
    </div>
    <!-- 幼儿板块 -->
    <div class="Young">
      <div class="Young_cont">
        <h1>影视拍摄</h1>
        <p>
          中润恒通公司在当今多元化媒体发展的潮流中，其影视拍摄业务的启航，实则是源于对抖音平台宣传视频拍摄的深度理解和精准把握。随着短视频及社交媒体影响力的日益增强，公司敏锐洞察到市场对于高质量、富有创意的视觉内容需求，因而将业务触角逐渐延伸至门店形象宣传、企业文化展示、菜单设计与制作、精致菜品拍摄以及各类短片创作等多个领域。
          在这一过程中，中润恒通公司不仅独立深耕，更积极寻求跨界合作，成功牵手行业翘楚GoogView影像工作室和具有广泛影响力的分众传媒，共同探索并拓展流媒体时代的无限可能。三方凭借各自的优势资源和技术力量，致力于打造引领潮流、深入人心的影视作品，为客户提供全方位、立体化的宣传解决方案，从而在波澜壮阔的流媒体时代中勇立潮头，成为真正的时代弄潮儿。通过这样的强强联合与持续创新，中润恒通公司的影视拍摄业务正以前所未有的活力和影响力，塑造着新时代的品牌传播格局。
        </p>
        <div class="Young_cont_img">
          <img src="../assets/img/y7.png" alt="" />
          <img src="../assets/img/y2.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 先进完备的课程体系 -->
    <div class="System">
      <div class="System_cont">
        <h1>影视拍摄业务结构</h1>
        <div class="System_cont_item">
          <div class="System_cont_item1">
            <img src="../assets/img/y1.png" alt="" />
            <div class="System_cont_item1_text">
              <h1>短剧拍摄</h1>
              <p>
                我们的短剧拍摄业务旨在通过精心构建的情节脉络，生动塑造角色形象，以及运用创新的拍摄技术和高质量的视听效果，为客户打造具有高度观赏性和传播力的作品。同时，我们深谙市场需求，紧跟时代潮流，力求将时下热门话题、社会现象以及深入人心的情感元素融入短剧中，使之更具话题性和影响力。

此外，我们团队拥有丰富的行业经验和专业技术能力，能够根据客户的不同需求，量身定制各类风格迥异、类型多样的短剧作品，包括但不限于喜剧、悬疑、科幻、情感等多种题材。在项目执行过程中，我们将全程秉持高效、专业的服务理念，确保每一个环节都精益求精，力求打造出满足甚至超越客户期待的精品短剧。
              </p>
            </div>
          </div>
          <div class="System_cont_item1">
            <div class="System_cont_item1_text">
              <h1>宣传片拍摄</h1>
              <p>
                企业宣传片拍摄业务作为一种极具影响力的品牌传播手段，日益受到各类型企业的高度重视。它不仅是一种视觉艺术的表现形式，更是企业形象塑造、产品推广、企业文化展示的重要载体。此项服务致力于为企业量身定制全方位、多层次的视听盛宴，通过专业策划、创意构思、高端拍摄技术以及后期精良制作，将企业的核心价值、发展历程、优势特色等关键信息以生动直观且富有感染力的方式呈现出来。

具体来说，企业宣传片拍摄业务涵盖了从前期的需求分析、剧本创作、场景设计、角色选定到中期的实地拍摄、特效制作，再到后期的剪辑合成、配音配乐、色彩校正等一系列环节。我们坚持以客户为中心，深入了解企业的内在需求与外在诉求，力求在宣传片中精准传达企业精神内涵，提升品牌形象，扩大市场影响力。


              </p>
            </div>
            <img src="../assets/img/y3.png" alt="" />
          </div>
          <div class="System_cont_item1">
            <img src="../assets/img/y6.png" alt="" />
            <div class="System_cont_item1_text">
              <h1>菜品菜单拍摄</h1>
              <p>
                在面对餐饮企业的核心业务组成部分——餐单菜品设计与呈现这一环节中，我们尤其重视对于每一道菜品的专业拍摄。这不仅涵盖了各类精致佳肴、地方特色美食，还包括饮品、甜点等多元化的餐饮产品。菜品拍摄的目的在于通过视觉艺术手段，真实而生动地展现每一道菜品的色香味形，以及其背后蕴含的烹饪技艺与文化内涵。

我们致力于为餐饮企业提供全方位、高品质的菜品拍摄服务，从食材的选择、摆盘的艺术、光影的运用到后期的图像处理，每一个细节都力求完美，以期能够吸引顾客的目光，刺激他们的味蕾，从而提升餐饮企业在市场上的品牌形象与竞争力。

拍摄过程中，我们会充分考虑菜品的特性，尊重每一种食材的独特性，捕捉最能体现菜品特色的瞬间，同时结合餐厅的整体风格和定位，确保拍摄出的菜品图片既符合品牌调性，又能引发消费者的就餐欲望。此外，我们还将不断探索创新的拍摄手法和技术，力求让餐饮企业的餐单菜品在视觉表现上达到新的高度，助力企业在激烈的市场竞争中脱颖而出。
              </p>
            </div>
          </div>
          <div class="System_cont_item1"></div>
        </div>
      </div>
    </div>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "影视拍摄",
      Img1: require("../assets/img/keji/矿山banner1.png"),
      arrList: [
        {
          h1: "水水水水",
          p: "共建婴幼儿托育服务与管理类专业，旨在坚持立德树人，面向城乡各级各类早教中心、幼儿园、托育机构、家庭教育机构，培养从事幼儿园保育、0-6婴幼儿照护、医教结合、医育一体，德智体美全面发展的高素质劳动者和技能型人才",
        },
        {
          h1: "水水水水",
          p: "共建婴幼儿托育服务与管理类专业，旨在坚持立德树人，面向城乡各级各类早教中心、幼儿园、托育机构、家庭教育机构，培养从事幼儿园保育、0-6婴幼儿照护、医教结合、医育一体，德智体美全面发展的高素质劳动者和技能型人才",
        },
        {
          h1: "水水水水",
          p: "共建婴幼儿托育服务与管理类专业，旨在坚持立德树人，面向城乡各级各类早教中心、幼儿园、托育机构、家庭教育机构，培养从事幼儿园保育、0-6婴幼儿照护、医教结合、医育一体，德智体美全面发展的高素质劳动者和技能型人才",
        },
      ],
      arrList2: [
        {
          h1: "水水水水",
          p: "共建婴幼儿托育服务与管理类专业，旨在坚持立德树人，面向城乡各级各类早教中心、幼儿园、托育机构、家庭教育机构，培养从事幼儿园保育、0-6婴幼儿照护、医教结合、医育一体，德智体美全面发展的高素质劳动者和技能型人才",
        },
        {
          h1: "水水水水",
          p: "共建婴幼儿托育服务与管理类专业，旨在坚持立德树人，面向城乡各级各类早教中心、幼儿园、托育机构、家庭教育机构，培养从事幼儿园保育、0-6婴幼儿照护、医教结合、医育一体，德智体美全面发展的高素质劳动者和技能型人才",
        },
      ],
      arrList3: [
        {
          url: require("../assets/img/youjiao/蒙版(1).png"),
          h1: "创新专业化人才培养体制",
          p: "建立产学深度融合的对接机制、校企深度合作的共建机制以及“校企一体化”的教学管理运行机制，形成“开放、灵活、多样”的协同育人新机制，全面梳理健全教育教学、科研、人资、财务等各项管理制度，形成相互衔接、较为完备的制度保障体系",
        },
        {
          url: require("../assets/img/youjiao/蒙版(1).png"),
          h1: "创新专业化人才培养体制",
          p: "建立产学深度融合的对接机制、校企深度合作的共建机制以及“校企一体化”的教学管理运行机制，形成“开放、灵活、多样”的协同育人新机制，全面梳理健全教育教学、科研、人资、财务等各项管理制度，形成相互衔接、较为完备的制度保障体系",
        },
        {
          url: require("../assets/img/youjiao/蒙版(1).png"),
          h1: "创新专业化人才培养体制",
          p: "建立产学深度融合的对接机制、校企深度合作的共建机制以及“校企一体化”的教学管理运行机制，形成“开放、灵活、多样”的协同育人新机制，全面梳理健全教育教学、科研、人资、财务等各项管理制度，形成相互衔接、较为完备的制度保障体系",
        },
        {
          url: require("../assets/img/youjiao/蒙版(1).png"),
          h1: "创新专业化人才培养体制",
          p: "建立产学深度融合的对接机制、校企深度合作的共建机制以及“校企一体化”的教学管理运行机制，形成“开放、灵活、多样”的协同育人新机制，全面梳理健全教育教学、科研、人资、财务等各项管理制度，形成相互衔接、较为完备的制度保障体系",
        },
        {
          url: require("../assets/img/youjiao/蒙版(1).png"),
          h1: "创新专业化人才培养体制",
          p: "建立产学深度融合的对接机制、校企深度合作的共建机制以及“校企一体化”的教学管理运行机制，形成“开放、灵活、多样”的协同育人新机制，全面梳理健全教育教学、科研、人资、财务等各项管理制度，形成相互衔接、较为完备的制度保障体系",
        },
        {
          url: require("../assets/img/youjiao/蒙版(1).png"),
          h1: "创新专业化人才培养体制",
          p: "建立产学深度融合的对接机制、校企深度合作的共建机制以及“校企一体化”的教学管理运行机制，形成“开放、灵活、多样”的协同育人新机制，全面梳理健全教育教学、科研、人资、财务等各项管理制度，形成相互衔接、较为完备的制度保障体系",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 幼儿板块 */
.Young {
  width: 100vw;
  height: 971px;
}
.Young_cont {
  width: 1200px;
  margin: 0 auto;
}
.Young_cont > h1 {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
  text-align: center;
  margin-top: 61px;
}
.Young_cont p {
  margin-top: 83px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 32px;
}
.Young_cont_img {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.Young_cont_img img {
  width: 585px;
  height: 340px;
}
/*合作模式  */
.Cooperation {
  border: dashed;
  width: 100vw;
  height: 831px;
  background-image: url("../assets/img/youjiao/摄图网_500924990_banner.png");
}
.Cooperation_cont > h1 {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #772020;
  line-height: 45px;
  text-align: center;
  padding-top: 73px;
}
.Cooperation_cont {
  width: 1200px;
  margin: 0 auto;
}
.Cooperation_cont_item {
  width: 1200px;
  margin-top: 51px;
}
.Cooperation_cont_item_top1 {
  border-radius: 8px;

  width: 373px;
  height: 273px;
  background: #23337f;
}
.Cooperation_cont_item_top1 > h1 {
  text-align: center;
  padding-top: 51px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}
.Cooperation_cont_item_top1 p {
  width: 308px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin: 22px 32px 0 32px;
}
.Cooperation_cont_item_top {
  display: flex;
  justify-content: space-between;
}
.Cooperation_cont_item_btm {
  width: 787px;
  margin: 36px 0 0 209px;
  display: flex;
  justify-content: space-between;
}
/* 优势特色 */
.Features_cont {
  width: 1200px;
  margin: 0 auto;
}
.Features_cont > h1 {
  text-align: center;
  margin: 80px 0 60px;
}
.Features_cont_item {
  border: dashed;
  display: flex;
}
.Features_cont_item_text {
  width: 749px;
  height: 460px;
  background: #f8f8f8;
}
.Features_cont_item_text > h1 {
  padding: 60px 64px 19px 64px;

  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}
.Features_cont_item_text p {
  padding-left: 64px;
  width: 622px;
  height: 288px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}
/* 创新人才培养 */
.Cultivate_cont {
  width: 1200px;
  margin: 0 auto;
}
.Cultivate_cont > h1 {
  text-align: center;
  margin-top: 92px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}
.Cultivate_cont_item {
  height: 677px;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.Cultivate_cont_item1 {
  border: dashed;
  position: relative;
  width: 580px;
  height: 199px;
}
.Cultivate_cont_item1 img {
  position: absolute;
}
.Cultivate_cont_item1 h1 {
  top: 30px;
  left: 40px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 32px;
  position: absolute;
}
.Cultivate_cont_item1 p {
  top: 74px;
  left: 40px;
  width: 500px;
  height: 96px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  position: absolute;
}
/* 体系 */
.System_cont {
  width: 1200px;
  height: 1620px;
  margin: 0 auto;
}
.System_cont > h1 {
  margin: 80px 0 60px 0;
  text-align: center;
}
.System_cont_item1 {
  margin-bottom: 40px;
  display: flex;
}
.System_cont_item1_text {
  width: 740px;
  height: 340px;
  background: #f8f8f8;
}
.System_cont_item1_text > h1 {
  padding: 57px 0 0 86px;

  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}
.System_cont_item1_text p {
  padding: 24px 0 0 86px;

  width: 568px;
  height: 168px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>
