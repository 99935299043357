<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>家政</span></div>
    </div>
    <!--  -->
    <div class="NewsDetails">
      <div class="NewsDetails_cont">
        <div class="NewsDetails_cont_title">
          <h1>发展数字经济 共享包容增长 为经济发展插上“数字翅膀”</h1>
          <div class="NewsDetails_cont_title_span">
            <img src="../assets/img/newsDetail/矩形.png" alt="" /><span
              >发布时间：2019-11-15&ensp;&ensp;
            </span>
            <img src="../assets/img/newsDetail/矩形(1).png" alt="" /><span
              >浏览次数：602
            </span>
          </div>
        </div>
        <div class="NewsDetails_cont_text">
          <p>
            中润恒通，面对日新月异的市场需求以及行业深度变革的趋势，积极响应并加速推进自身的数字化转型战略。作为业界翘楚，我们紧随科技发展步伐，充分利用最先进的容器化技术，自主研发了一系列创新性的数字化运营系统与管理系统，以实现企业运营效率的大幅提升和业务流程的全面优化。
          </p>
          <p>
            中润恒通积极拥抱并加速其数字化转型战略的落地执行，通过加强软件开发能力，推动品牌向更高层次发展。
            作为行业的领跑者，我们紧跟科技发展的最新趋势，采用尖端的容器化技术，独立研发了一整套具有创新性的数字运营和管理平台，旨在显著提升企业的操作效率和优化业务流程。
            我们的主营业务覆盖从品牌策划到门店设计、装修、筹备，以及物料设计与供应链支持等全方位的服务。
            此外，我们还提供个性化解决方案，包括收银支持和门店运营管理等，致力于将最新的产品和技术应用于各行各业的数字化转型中。
            通过与行业合作伙伴携手合作，我们致力于服务于各行业的数字化进程，共同推进智慧建设的实现。
            例如：
            我们在公司选址、注册、财税服务、商标设计与注册、大屏会议系统（goodview），以及广告标牌（上海仙视goodview,
            深圳数拓，三星，小米，联想等）等领域提供专业服务。
            同时，我们也提供餐厅菜牌设计、宣传片拍摄、菜单和菜品摄影，以及构建公司内控系统（包含oa系统、crm、财务系统、天才商龙、美团、客如云等收银系统）、供应链管理、会员系统、库存（进销存）管理、连锁配送、采购系统，以及扫码点餐、酒店管理和财税管理等一系列服务。
            在人员管理和绩效管理系统方面，我们采用微信、钉钉等工具，确保高效的团队协作与业绩跟踪；而在酒店管理系统方面，我们提供天欣、中软、西软、满江红、绿云等品牌的优质服务。
            除此之外，我们还专注于门店活动策划、保洁和设备维修等细节服务，全面满足客户需求。
          </p>
          <p>
            目前，这些精心研发的产品已进入关键的市场投放阶段，并将按照既定计划逐步推向市场，以满足不同客户群体对于智能化、便捷化管理及运营的需求。美舒家品牌坚信，通过这一系列数字化产品的广泛应用，必将有力推动整个行业的数字化进程，同时也将进一步巩固和提升美舒家品牌在业界的领先地位。
          </p>
        </div>
        <div class="NewsDetails_cont_img">
          <img src="img/gh_bbf169b1ac92_860 (7).22f3beae.jpg" alt="" />
        </div>
        <!-- 上一篇 下一篇 -->
        <Next ref="childData"></Next>
        <div class="NewsDetails_cont_btm">
          <div>相关新闻</div>
          <p>更多 ></p>
        </div>
        <div class="NewsDetails_cont_btm2">
          <div class="NewsDetails_cont_btm2_1">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews0">
                发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/jinrong">
                美舒家品牌，因创新而卓越，焕新生活从此开始！...
              </a>
            </p>
          </div>
          <div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
            <div>
              <a href="https://www.meishujia-cloud.com/#/TradeNews1">
                清凉一夏：美舒家为西安国润城前10位业主提供免费空调清洗服务！
              </a>
            </div>
            <p>2021-05-30</p>
            <p>
              <a href="https://www.meishujia-cloud.com/#/TradeNews3">
                美舒家西安华润二十四城半价油烟机清洗我来了...
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import Next from "../components/NextLastArtcle.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
    Next,
  },
  data() {
    return {
      h1: "中润恒通新闻",
      Img1: require("../assets/img/keji/矿山banner1.png"),
      // 这是我在父的组件里 定义的变量 表示当前是数组里第1个页面
      artcleID: 0,

      p1: "更多最新精彩资讯，感受企业魅力",
    };
  },

  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_cont_text div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}
.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}

.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>
